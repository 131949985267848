/* global _PREVIEW, _displayID, _DISPLAY_UPDATED, _SERVED_APP, _applicationData, _flow, _DISPLAY_ONLINE, _VALOTA_REST, fetchDataCB, _DATA_REFRESH_TIME */

/**
 *
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 * Created by ukah on 29.12.2016.
 */

/**
 * Fetch data from the server
 *
 * @param {boolean} single_fetch whether to do fetch only once
 * @returns {undefined}
 */
function fetchData() {

	var request = {action: "valota_api/virtual_displays/get_data"};
	// send display id
	request.displayUUID = _displayID;

	request.displayUpdated = _DISPLAY_UPDATED;
	request.sso = _SSO_TYPE;
	request.idp = _SSO_AZURE.idp;
	request.oid = _SSO_AZURE.oid;
	request.session = _SSO_AZURE.session;
	
	if (hasApps()) {
		request.apps = [];

		for (var i = 0; i < _applicationData.length; ++i) {
			var ad = _applicationData[i];
			var add_on = {};
			add_on.uuid = ad.uuid;

			// source
			if (ad.source) {
				add_on.source = {
					id: ad.source.id
				};

				if (typeof ad.source.changeID !== 'undefined') {
					add_on.source.changeID = ad.source.changeID;
				}

				if (typeof ad.source.updateTime !== 'undefined') {
					add_on.source.updateTime = ad.source.updateTime;
				}

			}

			if (ad.palette) {
				add_on.paletteId = ad.palette.id;
			}

			if (ad.customs) {
				add_on.customsUpdateTime = ad.customs.updateTime;
			}

			request.apps.push(add_on);


		}
	}

	if (hasFlow() && _flow.config.uuid !== 'local') {
		request.flow = {
			'uuid': _flow.config.uuid,
			'updateId': _flow.config.updateId
		};
	}

	document.getElementById('stats_display_started').innerHTML = gat(Math.round((Date.now() - _DISPLAY_ONLINE.getTime()) / 1000));

	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,

		dataType: "json"
	}).done(fetchDataCB)
		.fail(function (xhr, st, err) {

			if (xhr.status === 503) {
				// server under maintenance
				if (xhr.getResponseHeader("Retry-After") !== null &&
					parseInt(xhr.getResponseHeader("Retry-After")) > _DATA_REFRESH_TIME) {
					window.clearTimeout(_REQUEST_TIMER);
					var time = parseInt(xhr.getResponseHeader("Retry-After"));

					if (time > 7200) {
						time = 7200;
					}
					failedFetch("Server under maintenance for " + Math.ceil(time / 60) + " minutes, next try at " + new Date((Date.now() / 1000 + time) * 1000));

					console.log("[FETCH] server 503, next in " + time + "seconds");
					_REQUEST_TIMER = setTimeout(fetchData, time * 1000);
				} else {
					var mes = 'Fetch failed with an error: ' + err + ' (' + st + ')';
					failedFetch(mes);
				}
			} else {
				if (xhr.status === 0) {
					failedFetch(_('Cannot contact the server'));
				} else {
					var mes = 'Fetch failed with an error: ' + xhr.status + " " + err + ' (' + st + ')';
					failedFetch(mes);
				}
			}
		});
	console.log("[FETCH]", request);
	_LAST_TIMER_EPOCH_S = Date.now() / 1000;
	_REQUEST_TIMER = setTimeout(fetchData, _DATA_REFRESH_TIME * 1000);
}