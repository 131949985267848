/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */


/* global ValotaEngine, _VALOTA_CUR_BG, _applicationData, _PREVIEW, closePreview, _viewDistance, _READY_CALLBACK, _VALOTA_DEVICE, ValotaAndroid, _VALOTA_EXTENSION, _FLOW_HANDLER, _VALOTA_TIZEN */
function Valota(el) {

	var _this = this;

	this.owner = el;

	this.uuid = el.frameElement.valotaAppId;

	var cacheList = {};
	var memoryList = {};

	this.Sources = {

		get: function () {

			return ValotaEngine.Sources.get(_this.uuid);
		},
		getLatestContent: function () {
			return ValotaEngine.Sources.getLatestContent(_this.uuid);
		}

	};

	this.Palette = {
		getColor: function (num) {
			return ValotaEngine.Palette.get(_this.uuid, 'color', num);
		},
		getSupportColor: function (num) {
			return ValotaEngine.Palette.get(_this.uuid, 'support', num);
		},
		getFont: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'font');
		},
		getFontColor: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'bodyFontColor');
		},
		getBodyFont: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'font');
		},
		getBodyFontColor: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'bodyFontColor');
		},
		getTitleFont: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'titlefont');
		},
		getTitleFontColor: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'titleFontColor');
		},
		getBackgroundColor: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'appbgcolor', _VALOTA_CUR_BG);
		},
		getTransitionTime: function (num) {
			return ValotaEngine.Palette.get(_this.uuid, 'transitionTime', num);
		},
		getUnobtrusiveColor: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'unobtrusive', _VALOTA_CUR_BG);
		},
		getUnobtrusiveBgColor: function () {
			return ValotaEngine.Palette.get(_this.uuid, 'unobtrusivebg', _VALOTA_CUR_BG);
		},
		getFontSize: function () {
			var vd = Math.min(Math.max(_viewDistance, 0.5), 5);
			var fs = 2.5 + (0.95 * (vd - 0.5) / 0.5);
			return fs + 'vmin';
		},
		canUseBorderMargins: function () {
			return true;
		}
	};

	/**
	 * @param uuid for .Video.* methods is optional and should be used if same
	 * app wants to play multiple videos at the same time (such as a combined
	 * app)
	 * @param rect can declare additional top and left coordinates inside the
	 * app
	 **/
	this.Video = {
		setMuteVideo: function (status, uuid) {
			if (typeof uuid === 'undefined') {
				uuid = null;
			}
			ValotaEngine.Video.setMuteVideo(uuid === null ? _this.uuid : uuid, status);
		},
		cacheFile: function (url, cachedCallback) {
			ValotaEngine.Video.cacheFile(url, cachedCallback);
		},
		playVideo: function (url, elem, playStartedCB, endedCB, errorCB, uuid, rect) {
			if (typeof uuid === 'undefined') {
				uuid = null;
			}
			if (typeof rect === 'undefined') {
				rect = null;
			}
			var loc = getStoryLoc(_this.uuid);
			var iframeRect = _applicationData[loc].container.getBoundingClientRect();
			var coordObj = {left: iframeRect.left, top: iframeRect.top};
			if (rect !== null) {
				coordObj.top += rect.top;
				coordObj.left += rect.left;
			}
			if (_this.uuid !== _FLOW_HANDLER._CURRENT_CONTENT.getApp().uuid) {
				console.error("[Engine] tried to play video even though not the active app", _this.uuid);
				window.setTimeout(function () {
					errorCB("tried to play video even though not the active app");
				}, 10);
				return;
			}
			// TODO keep track of owner videos
			if (!(_this.uuid in ValotaEngine.Video.videoRealOwners)) {
				ValotaEngine.Video.videoRealOwners[_this.uuid] = [];
			}
			ValotaEngine.Video.videoRealOwners[_this.uuid].push({
				elem: elem,
				uuid: (uuid === null ? _this.uuid : uuid)
			});
			ValotaEngine.Video.playVideo(uuid === null ? _this.uuid : uuid, url, elem, playStartedCB, endedCB, errorCB, coordObj);
		},
		replayVideo: function (elem, playStartedCB, errorCB, uuid) {
			if (typeof uuid === 'undefined') {
				uuid = null;
			}
			if (_this.uuid !== _FLOW_HANDLER._CURRENT_CONTENT.getApp().uuid) {
				console.error("[Engine] tried to replay video even though not the active app", _this.uuid);
				window.setTimeout(function () {
					errorCB("tried to replay video even though not the active app");
				}, 10);
				return;
			}
			ValotaEngine.Video.replayVideo(uuid === null ? _this.uuid : uuid, elem, playStartedCB, errorCB);
		},
		stopVideo: function (elem, uuid) {
			if (typeof uuid === 'undefined') {
				uuid = null;
			}
			ValotaEngine.Video.stopVideo(uuid === null ? _this.uuid : uuid, elem);
			for (var i = ValotaEngine.Video.videoRealOwners[_this.uuid].length - 1; i >= 0; i--) {
				if (ValotaEngine.Video.videoRealOwners[_this.uuid][i].elem === elem && ValotaEngine.Video.videoRealOwners[_this.uuid][i].uuid === (uuid === null ? _this.uuid : uuid)) {
					ValotaEngine.Video.videoRealOwners[_this.uuid].splice(i, 1);
				}
			}
		},
		resizeVideo: function (elem, uuid, rect) {
			if (typeof uuid === 'undefined') {
				uuid = null;
			}
			if (typeof rect === 'undefined') {
				rect = null;
			}
			var loc = getStoryLoc(_this.uuid);
			var iframeRect = _applicationData[loc].container.getBoundingClientRect();
			var coordObj = {left: iframeRect.left, top: iframeRect.top};
			if (rect !== null) {
				coordObj.top += rect.top;
				coordObj.left += rect.left;
			}
			ValotaEngine.Video.resizeVideo(uuid === null ? _this.uuid : uuid, elem, coordObj);
		}
	};

	this.getViewDistance = function () {
		return Math.min(Math.max(_viewDistance, 0.5), 5);
	};


	this.tinycolor = function (color) {
		return tinycolor(color);
	};

	this.tinycolorLib = tinycolor;

	this.newContent = function (id) {
		newContentOnApp(_this.uuid, isset(id) ? id : null);
	};

	this.Customs = {
		get: function (key) {
			return ValotaEngine.Customs.get(_this.uuid, key);
		}
	};

	function allLoaded(num) {
		if (!_applicationData[num].notReadyCalled) {
			_applicationData[num].ready = true;
			console.log("[Engine] " + num + " ready, fonts loaded");
			// remove cursor
			// TODO interactive should have cursor?
			// inject this css to the iframe
			if (!_PREVIEW && _applicationData[num].container.contentDocument.getElementById('valota_cursor_css') === null) {
				var style = document.createElement('style');
				style.type = 'text/css';
				style.setAttribute('id', 'valota_cursor_css');
				var cssBody = "body{cursor:none;}";
				if (style.styleSheet) {
					style.styleSheet.cssText = cssBody;
				} else {
					style.innerHTML = cssBody;
				}
				_applicationData[num].container.contentDocument.getElementsByTagName('head')[0].appendChild(style);
			}
			if (_PREVIEW) {
				_applicationData[num].container.contentDocument.addEventListener('click', closePreview, false);
			}
			if (_READY_CALLBACK !== null) {
				_READY_CALLBACK();
			}
                        if(_applicationData[num].uuid===_overlayApp){
                                playOverlay(num);
                        }
                        else {
                                runStories();
                        }
		} else {
			console.log("[Engine] " + num + " all loaded, but not ready called between");
		}
		_applicationData[num].notReadyCalled = false;
	}

	this.iAmReady = function () {
		var storyLoc = getStoryLoc(_this.uuid);
		console.log("[Engine] " + storyLoc + " ready");
		loadFonts(storyLoc, allLoaded);
		_applicationData[storyLoc].notReadyCalled = false;

	};

	this.iAmNotReady = function () {
		var storyLoc = getStoryLoc(_this.uuid);
		console.log("[Engine] " + storyLoc + " not ready");
		_applicationData[storyLoc].ready = false;
		_applicationData[storyLoc].notReadyCalled = true;
		try {
			ValotaEngine.Video.stopVideosByApp(_applicationData[storyLoc].uuid);
		} catch (e) {
			logError("iAmNotReady video stop errored " + e.toString(), _applicationData[storyLoc].uuid, 'warning');
		}
		if (currentStory === storyLoc) {
			_applicationData[currentStory].container.className = 'inactive';
			currentStory = -1;
			_STARTING_PLAY = false;
			runStories();
		}
	};

	function fileCachedCallback(name, url, unused, status) {
		if (typeof cacheList[name] === 'undefined') {
			throw new Error(name + " not found in cache list");
		}
		cacheList[name].cb(url, status);
		delete cacheList[name];
	}

	this.cacheFile = function (url, cachedCallback) {
		console.warn("[Engine] obsolete use Valota.Video.cacheFile instead");
		return false;
	};

	this.loadFromDisk = function (url, urlCallback) {
		console.error("[Valota Engine] obsolete loadFromDisk");
	};

	this.freeFileFromMemory = function (url) {
		console.error("[Valota Engine] obsolete freeFileFromMemory");
	};

	this.runTime = function (s) {
		console.warn("[Engine] obsolete " + _this.uuid + " runtime " + s);
		setRunTime(getStoryLoc(_this.uuid), s);
	};

	this.cycleTime = function (s) {
		console.log("[Engine] " + _this.uuid + " cycle time " + s);
		setCycleTime(getStoryLoc(_this.uuid), s);
	};

	this.preferredCycles = function (s) {
		console.log("[Engine] " + _this.uuid + " Preferred cycles " + s);
		setPreferredCycles(getStoryLoc(_this.uuid), s);
	};

	this.cycleMe = function () {
		console.log("[Engine] self cycle " + _this.uuid);

		var num = getStoryLoc(_this.uuid);

		// check that app is currently running
		if (num === currentStory && _FLOW_HANDLER) {
			//set cycleRun to cycleTime so it will cycle next tick
			_FLOW_HANDLER.setCycleRun(_FLOW_HANDLER.cycleTime());

		}

	};

	this.logToServer = function (message, severity) {
		if (typeof severity !== "string") {
			severity = "error";
		}
		logError(message, _this.uuid, severity);
	};

	this.run = function (functionName, ...args) {
		if (typeof _this[functionName] === 'function') {
			_this[functionName](...args);
		} else {
			console.error("[Engine] invalid function called" + functionName);
		}
	};

	this.contentIsInPlace = function (bBool) {
		console.log("[Engine] Content is in place " + _this.uuid);

		var num = getStoryLoc(_this.uuid);
		if (num !== -1 && num === currentStory) {
			if (typeof _applicationData[num].lastScreenshot === 'undefined' || Date.now() - _applicationData[num].lastScreenshot > _SCREENSHOT_INTERVAL_S * 1000) {
				if (shouldSendScreenshotData()) {
					console.log("[Engine] sending screenshot for " + _this.uuid);
					sendScreenshot(_this.uuid, false);
				}

			}
		}

	};

}


