/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */
/* global URL, encodeURI */

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.FileSystem = {
	inited: false,
	callback: null,
	valotaFS: null,
	ready: false,
	files: {},
	videoOwners: [],
	cacheQueue: [],
	start: function () {
		/*
		 var requestedBytes = 1024 * 1024 * 10000; // 10G
		 //window.webkitRequestFileSystem(window.PERSISTENT, 1024 * 1024 * 100000, ValotaEngine.successCB, ValotaEngine.fsErrorCB); // 100 G
		 navigator.webkitPersistentStorage.requestQuota(requestedBytes, function (grantedBytes) {
		 window.webkitRequestFileSystem(window.PERSISTENT, grantedBytes, ValotaEngine.FileSystem.successCB, ValotaEngine.FileSystem.fsErrorCB);
		 }, function (e) {
		 console.error('[Engine FS] Error', e);
		 }
		 );*/
		ValotaEngine.FileSystem.ready = true;
	},
	successCB: function (fs) {
		ValotaEngine.FileSystem.valotaFS = fs;
		ValotaEngine.FileSystem.ready = true;
		navigator.webkitPersistentStorage.queryUsageAndQuota(
			function (usedBytes, grantedBytes) {
				console.log('[Engine FS] we are using ' + usedBytes + ' of ' + grantedBytes + ' bytes');
			},
			function (e) {
				console.log('[Engine FS] Error', e);
			}
		);
	},
	fsErrorCB: function (e) {
		console.warn('[Engine FS] unable to open disk, caching not supported!', e);
	},
	clearUUID: function () {
	},
	saveUUID: function (uuid) {
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.warn("[Engine FS] cache callback " + url + ": " + status.status);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	cacheFile: function (name, url, callback) {
		window.setTimeout(function () {
			callback(name, url, null, {status: 'error'});
		}, 10);
		return false;
		if (!ValotaEngine.FileSystem.ready) {
			console.warn("[Engine FS] filesystem not ready");
			window.setTimeout(function () {
				callback(name, url, null, {status: 'error'});
			}, 10);
			return false;
		}

		if (typeof ValotaEngine.FileSystem.files[name] !== 'undefined') {
			if (ValotaEngine.FileSystem.files[name].localURL !== null) {
				window.setTimeout(function () {
					callback(name, url, null, {status: 'ok'});
				}, 10);
				return true;
			}
			throw "bad coding, should not have come this far";
		}
		if (ValotaEngine.FileSystem.callback !== null) {
			// cacheing something already, put this to queue
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.FileSystem.cacheQueue.push(tempObj);
			return;
		}
		ValotaEngine.FileSystem.callback = callback;
		ValotaEngine.FileSystem.files[name] = {};
		ValotaEngine.FileSystem.files[name].url = url;
		ValotaEngine.FileSystem.files[name].blob = null;
		ValotaEngine.FileSystem.files[name].localURL = null;
		ValotaEngine.FileSystem.loadURL(url, name);
		return true;
	},
	nextFromCacheQueue: function () {
		if (ValotaEngine.FileSystem.callback !== null) {
			return;
		}

		var cacheRetrievalObj = ValotaEngine.FileSystem.cacheQueue.shift();
		if (typeof cacheRetrievalObj !== "undefined") {
			ValotaEngine.FileSystem.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
		}

	},
	/**
	 * checks the cache entries if any can be deleted due to unuse
	 * @returns {undefined}
	 */
	checkAndPurgeCache: function () {
		var dirReader = ValotaEngine.FileSystem.valotaFS.root.createReader();
		var loopFunc = function () {
			dirReader.readEntries(function (results) {
				if (results.length) {
					for (var i = results.length - 1; i >= 0; i--) {
						ValotaEngine.FileSystem.checkAndDelete(results[i].name);
					}
					loopFunc();
				}
			}, ValotaEngine.FileSystem.getNonfatalErrorCB("readEntries delete"));
		};
		loopFunc();
	},
	/**
	 * deletes a file from cache
	 * @param {type} filename
	 * @returns {undefined}
	 */
	deleteFromCache: function (filename) {
		ValotaEngine.FileSystem.valotaFS.root.getFile(filename, {create: false}, function (fileEntry) {
			fileEntry.remove(function () {
				console.log('[Engine FS] File removed ' + filename);
				if (ValotaEngine.files.hasOwnProperty(filename)) {
					delete ValotaEngine.files[filename];
				}
			}, ValotaEngine.FileSystem.getNonfatalErrorCB('remove'));
		}, ValotaEngine.FileSystem.getNonfatalErrorCB('root.getFile delete'));
		localStorage.removeItem('file_used_' + ValotaEngine.FileSystem.encodeKey(filename));
	},
	/**
	 * checks whether file has been used within last 8 days and asks to be deleted if not
	 * @param {type} filename
	 * @returns {undefined}
	 */
	checkAndDelete: function (filename) {

		if (localStorage.getItem('file_used_' + ValotaEngine.FileSystem.encodeKey(filename)) === null) {
			ValotaEngine.FileSystem.deleteFromCache(filename);
			return;
		}
		var lastUsed = parseFloat(localStorage.getItem('file_used_' + ValotaEngine.FileSystem.encodeKey(filename)));
		if ((new Date() / 1000) - lastUsed > 691200) {
			// files accessed more than 8 days ago will be purged
			ValotaEngine.FileSystem.deleteFromCache(filename);
		}

	},
	/**
	 * non fatal errors get written to log
	 * 
	 * @param {type} i
	 * @returns {Function}
	 */
	getNonfatalErrorCB: function (i) {
		return function (e) {
			console.error("[Engine FS] " + i + ":" + e.toString());
		};
	},
	/**
	 * reads filenames to check whether a file exists in disk already
	 * @param {string} name
	 * @param {string] url
	 * @returns {undefined}
	 */
	loadURL: function (url, name, regression) {
		if (typeof regression === 'undefined') {
			regression = false;
		}
		var dirReader = ValotaEngine.FileSystem.valotaFS.root.createReader();
		console.log("[Engine FS] loadin URL " + url);
		var loopFunc = function () {
			dirReader.readEntries(function (results) {
				if (results.length) {
					for (var i = 0; i < results.length; i++) {
						if (results[i].name === name) {
							results[i].file(function (file) {
								ValotaEngine.FileSystem.files[results[i].name].localURL = URL.createObjectURL(file);
							});
							if (ValotaEngine.FileSystem.callback !== null) {
								ValotaEngine.FileSystem.callback(name, url, null, {status: 'ok'});
							} else {
								throw "no callback load url!?";
							}
							ValotaEngine.FileSystem.used(name);
							ValotaEngine.FileSystem.clear(name);
							ValotaEngine.FileSystem.callback = null;
							ValotaEngine.FileSystem.nextFromCacheQueue();
							return;
						}
					}
					loopFunc();
				} else {
					if (!regression) {
						ValotaEngine.FileSystem.loadURLOverXHR(url, name);
					} else {
						throw "bad coding, should have found file " + name;
					}
				}
			}, ValotaEngine.FileSystem.getFatalErrorCB("load URL", name, url));
		};
		loopFunc();
	},
	/**
	 * marks a file used at the moment this method is called
	 * @param {type} filename
	 * @returns {undefined}
	 */
	used: function (filename) {
		localStorage.setItem('file_used_' + ValotaEngine.FileSystem.encodeKey(filename), parseInt(new Date() / 1000));
		ValotaEngine.FileSystem.checkAndPurgeCache();
	},
	/**
	 * Clears the current disk command
	 * @param {string} name
	 * @returns {undefined}
	 */
	clear: function (name) {
		delete ValotaEngine.FileSystem.files[name].blob;
	},
	/**
	 * fatal error in file retrieval, must return error to webview
	 * @param {type} s
	 * @param {string] url
	 * @param {string} name
	 * @returns {Function}
	 */
	getFatalErrorCB: function (s, url, name) {
		return function (e) {
			ValotaEngine.FileSystem.files[name].status = "error";
			if (ValotaEngine.FileSystem.callback !== null) {
				ValotaEngine.FileSystem.callback(name, url, null, {status: 'error'});
				ValotaEngine.FileSystem.callback = null;
			} else {
				throw "no callback get fatal error!?";
			}
			ValotaEngine.FileSystem.nextFromCacheQueue();
			console.error("[Engine FS]", s, name, url, e);
			ValotaEngine.FileSystem.clear(name);
		};
	},
	/**
	 * encodes a string to be used as a storage key
	 * @param {type} key
	 * @returns {String}
	 */
	encodeKey: function (key) {
		return encodeURI(key).toString().replace(/[.]/g, "_");
	},
	/**
	 * creating a writer succeeded
	 * @param {type} fileWriter
	 * @param {string] url
	 * @param {string} name
	 * @returns {undefined}
	 */
	createWriterCB: function (fileWriter, url, name) {
		fileWriter.onwriteend = function () {
			console.log("[Engine FS] wrote " + name);
			// must read the file from disk
			ValotaEngine.FileSystem.loadURL(url, name, true);
		};
		fileWriter.onerror = function (e) {
			console.warn('[Engine FS] Write failed.', url, name, e);
			ValotaEngine.FileSystem.files[name].status = "error";
			if (ValotaEngine.FileSystem.callback !== null) {
				ValotaEngine.FileSystem.callback(name, url, null, {status: 'error'});
				ValotaEngine.FileSystem.callback = null;
			} else {
				throw "no callback create writer onerror!?";
			}
			ValotaEngine.FileSystem.nextFromCacheQueue();
			ValotaEngine.FileSystem.clear(name);
		};
		fileWriter.onabort = function (e) {
			console.warn('[Engine FS] Write failed.', url, name, e);
			ValotaEngine.FileSystem.files[name].status = "error";
			if (ValotaEngine.FileSystem.callback !== null) {
				ValotaEngine.FileSystem.callback(name, url, null, {status: 'error'});
				ValotaEngine.FileSystem.callback = null;
			} else {
				throw "no callback create writer onabort!?";
			}
			ValotaEngine.FileSystem.nextFromCacheQueue();
			ValotaEngine.FileSystem.clear(name);
			;
		};
		// Create a new Blob and write it
		fileWriter.write(new Blob([ValotaEngine.FileSystem.files[name].blob]));
	},
	/**
	 * creating a file succeeded
	 * @param {type} fileEntry
	 * @param {string] url
	 * @param {string} name
	 * @returns {undefined}
	 */
	getFileCB: function (fileEntry, url, name) {
		fileEntry.createWriter(function (fileWriter) {
			ValotaEngine.FileSystem.createWriterCB(fileWriter, url, name);
		}, ValotaEngine.FileSystem.getFatalErrorCB("getFileCB", url, name));
	},
	/**
	 * start a xhr to get a file over the network
	 * @param {string} filename
	 * @returns {undefined}
	 */
	loadURLOverXHR: function (url, filename) {
		// put to cache
		var xhr = new XMLHttpRequest();
		xhr.open('GET', ValotaEngine.FileSystem.files[filename].url, true);
		xhr.responseType = 'arraybuffer';
		xhr.onload = function () {
			console.log("[Engine FS] xhr load " + filename);
			if (this.status === 200) {
				ValotaEngine.FileSystem.files[filename].blob = this.response;
				// create new file to write to
				ValotaEngine.FileSystem.valotaFS.root.getFile(filename, {create: true, exclusive: true}, function (fileEntry) {
					ValotaEngine.FileSystem.getFileCB(fileEntry, url, filename);
				}, ValotaEngine.FileSystem.getFatalErrorCB("[Engine FS] xhr getFile", url, filename));
			} else {
				console.warn("[Engine FS] unable to load", filename, this);
				if (ValotaEngine.FileSystem.callback !== null) {
					ValotaEngine.FileSystem.callback(filename, url, null, {status: 'error'});
					ValotaEngine.FileSystem.callback = null;
				} else {
					throw "no callback loadurl over xhr!?";
				}
				ValotaEngine.FileSystem.nextFromCacheQueue();
				ValotaEngine.FileSystem.clear(filename);
			}
		};
		xhr.onerror = function (e) {
			console.error("[Engine FS] xhr fail", e, filename);
			ValotaEngine.FileSystem.files[filename].status = "error";
			if (ValotaEngine.FileSystem.callback !== null) {
				ValotaEngine.FileSystem.callback(filename, url, null, {status: 'error'});
				ValotaEngine.FileSystem.callback = null;
			} else {
				throw "no callback xhr onerror!?";
			}
			ValotaEngine.FileSystem.nextFromCacheQueue();
			ValotaEngine.FileSystem.clear(filename);
		};
		xhr.onabort = function (e) {
			console.error("[Engine FS] xhr abort", e, filename);
			ValotaEngine.FileSystem.files[filename].status = "error";
			if (ValotaEngine.FileSystem.callback !== null) {
				ValotaEngine.FileSystem.callback(filename, url, null, {status: 'error'});
				ValotaEngine.FileSystem.callback = null;
			} else {
				throw "no callback xhr onabort!?";
			}
			ValotaEngine.FileSystem.nextFromCacheQueue();
			ValotaEngine.FileSystem.clear(filename);
		};
		xhr.send();
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.FileSystem.files)) || ValotaEngine.FileSystem.files[name].loading) {
			throw "trying to play an uncached video";
		}
		if (!ValotaEngine.FileSystem.files[name].localURL === null) {
			throw "trying to play an uncached video, not in memory";
		}
		if (!(uuid in ValotaEngine.FileSystem.videoOwners)) {
			ValotaEngine.FileSystem.videoOwners[uuid] = {elem: null, videoStartedPlayingCB: null, videoEndedCB: null, videoErrorCB: null, videoConf: {mute: true}};
		}
		if (ValotaEngine.FileSystem.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.FileSystem.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

		ValotaEngine.FileSystem.videoOwners[uuid].elem = elem;
		ValotaEngine.FileSystem.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.FileSystem.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.FileSystem.videoOwners[uuid].videoErrorCB = errorCB;
		ValotaEngine.FileSystem.videoOwners[uuid].url = url;
//		ValotaEngine.FileSystem.videoOwners[uuid].engineElem = document.createElement('video');
		ValotaEngine.FileSystem.videoOwners[uuid].elem.addEventListener("ended", ValotaEngine.FileSystem.videoOwners[uuid].videoEndedCB);
		ValotaEngine.FileSystem.videoOwners[uuid].elem.addEventListener("error", ValotaEngine.FileSystem.videoOwners[uuid].videoErrorCB);
		ValotaEngine.FileSystem.videoOwners[uuid].elem.dataset.ownerUuid = uuid;
		//document.body.append(ValotaEngine.FileSystem.videoOwners[uuid].engineElem);
		ValotaEngine.FileSystem.videoOwners[uuid].elem.src = ValotaEngine.FileSystem.files[name].localURL;
		ValotaEngine.FileSystem.videoOwners[uuid].elem.muted = ValotaEngine.Video.getMuteStatus(uuid);
		ValotaEngine.FileSystem.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.FileSystem.videoOwners[uuid].elem.duration, uuid);
		}).catch(function (ev) {
			errorCB(ev, uuid);
		});
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		if (ValotaEngine.FileSystem.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to resize video when something else is playing";
		}
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.FileSystem.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.FileSystem.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}
		if (ValotaEngine.FileSystem.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		ValotaEngine.FileSystem.videoOwners[uuid].elem.pause();
		ValotaEngine.FileSystem.videoOwners[uuid].elem.removeEventListener("ended", ValotaEngine.FileSystem.videoOwners[uuid].videoEndedCB);
		ValotaEngine.FileSystem.videoOwners[uuid].elem.removeEventListener("error", ValotaEngine.FileSystem.videoOwners[uuid].videoErrorCB);
		ValotaEngine.FileSystem.videoOwners[uuid].elem.src = "";
		ValotaEngine.FileSystem.videoOwners[uuid].elem.load();
		//document.body.removeChild(ValotaEngine.FileSystem.videoOwners[uuid].engineElem);
		ValotaEngine.FileSystem.videoOwners[uuid].elem = null;
		ValotaEngine.FileSystem.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.FileSystem.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.FileSystem.videoOwners[uuid].videoStartedPlayingCB = null;
		//ValotaEngine.FileSystem.videoOwners[uuid].elem = null;
		ValotaEngine.FileSystem.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (ValotaEngine.FileSystem.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		ValotaEngine.FileSystem.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.FileSystem.videoOwners[uuid].elem.duration, uuid);
		}).catch(function (ev) {
			errorCB(ev, uuid);
		});
		ValotaEngine.FileSystem.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.FileSystem.videoOwners[uuid].videoErrorCB = errorCB;
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.FileSystem.videoOwners) {
			if (ValotaEngine.FileSystem.videoOwners[uuid].elem !== null) {
				ValotaEngine.FileSystem.stopVideo(uuid, ValotaEngine.FileSystem.videoOwners[uuid].elem);
			}
		}
	}
};