/* global ValotaEngine, _applicationData, _VALOTA_REST, currentStory, runPendingFunctions, _VALOTA_DEVICE */

/**
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 * Created by ukah on 29.12.2016.
 */


/**
 * Crunch our data
 *
 * @param {json} data contains response of the server
 * @returns {undefined}
 */
function fetchDataCB(data) {
	console.log("[FetchCB]");
	if (data.status !== "ok") {
		console.log(data);
		var re = data.response;
		if (typeof re !== 'undefined' && typeof re.sso !== 'undefined') {
			_LOGGED_IN = false;
			_SSO_TYPE = re.sso;
			localStorage.clear();
			if (_SSO_TYPE === 'azure') {
				_SSO_URL = re.ssoURL;
				_SSO_USE_BUTTON = re.ssoUseButtonInEmbedded;
				if (document.getElementById('valota_signon_frame') !== null) {
					document.getElementById('valota_signon_frame').parentElement.removeChild(document.getElementById('valota_signon_frame'));
				}
				var frame = document.createElement('iframe');
				frame.id = "valota_signon_frame";
				frame.style.height = "0px";
				frame.style.width = "0px";
				frame.style.border = "none";
				frame.sandbox = 'allow-scripts allow-forms allow-same-origin';
				document.body.appendChild(frame);
				frame.src = _SSO_URL;
				window.setTimeout(notLoggedIn, 1500);
				return;
			} else if (_SSO_TYPE === 'azure2') {
				notLoggedIn();
				return;
			}
		}
		var mes = data.status_messages;
		if (typeof mes !== 'undefined') {
			for (var i = 0; i < mes.length; i++) {
				console.error(mes[i]);
			}
		}
		localStorage.clear();
		waitForContent(false, true);
		document.getElementById('waiting_info').innerHTML = _("Virtual Display not displayable");
		return;
	}

	succesfulFetch();
	if (typeof _SERVED_APP !== "undefined" && _SERVED_APP && !staticServe()) {
		_SERVED_APP = null;
	}

	if (typeof data.response !== 'undefined') {

		var re = data.response;

		//Show emergency message
		if (typeof re.emergency_message === "string" && re.emergency_message !== "") {
			var emergency_elem = document.getElementById('valota_emergency');
			emergency_elem.innerHTML = re.emergency_message;
			var length = $(emergency_elem).text().length;
			var area = window.innerWidth * window.innerHeight;
			var fontSize = 0.7 * Math.sqrt(area / length);
			emergency_elem.style.fontSize = fontSize + "px";
			emergency_elem.style.display = "flex";
			ValotaEngine.Video.emergency();
			if (typeof _VALOTA_DEVICE !== 'undefined') {
				ValotaEngine.ChromeOS.emergency();
			}
		} else {
			document.getElementById('valota_emergency').html = "";
			document.getElementById('valota_emergency').style.display = "none";
			ValotaEngine.Video.emergencyOver();
			if (typeof _VALOTA_DEVICE !== 'undefined') {
				ValotaEngine.ChromeOS.emergencyOver();
			}
		}


		if (typeof (re.action) !== "undefined") {
			// we have an unclaimed display
			if (re.action === 'new_display') {

				ValotaEngine.ClaimDisplay.identifier = re.identifier;

				ValotaEngine.ClaimDisplay.show();

				localStorage.clear();

				return;
			}

			if (re.action === 'reload_app') {
				reloadApp();
				return;
			}

			if (re.action === 'reload') {
				location.reload();
				return;
			}

			if (re.action === 'new_display_uuid') {
				console.warn("[Engine FetchCB] new uuid");
				_displayID = re.new_display_uuid;
				setLocal('display', _displayID);
				_DISPLAY_UPDATED = null;
				saveUUID();
				fetchData(true);
				return;
			}

			if (re.action === 'hard_reload') {
				for (var i = 0; i < _applicationData.length; ++i) {
					if (!_applicationData[i].container) {
						continue;
					}
					try {
						_applicationData[i].container.contentWindow.location.reload(true);
					} catch (e) {
						console.error('reloading ' + _applicationData[i].uuid + "failed", e);
					}
				}
				window.setTimeout(function () {
					localStorage.clear();
					location.reload(true);
				}, 1000); // 1 second
				return;
			}


			if (re.action === 'deleted') {
				_applicationData = null;
				_displayID = null;
				_overlayApp = null;
				_displayDate = null;
				_displayName = null;
				_displayUser = null;
				_viewDistance = null;
				_ownerLogo = null;
				_STATIC_SERVE = null;
				_SERVED_APP = null;
				localStorage.clear();
				clearUUID();
				init();
				location.reload(true);
				return;
			}
			// TODO:do more actions??
		}

		if (typeof (re.support_action) !== "undefined") {
			for (var key = 0; key < re.support_action.length; ++key) {
				if (re.support_action[key] === 'log_db') {
					var request = {
						action: "valota_api/log/log_db",
						appUUID: "DB dump",
						displayUUID: _displayID,
						message: JSON.stringify(localStorage)
					};
					$.ajax({
						type: "POST",
						url: _VALOTA_REST,
						data: request,
						dataType: "json"
					}).done(function () {

					}).fail(function (xhr, st, err) {
						var mes = 'Failed to send log: ' + err + ' (' + st + ')';
						console.error("[FetchCB] " + mes);
					});
				}

				if (re.support_action[key] === 'send_screenshot') {
					setTimeout(takeScreenshot, 1000);
				}
			}
		}

		if (typeof re.display_status !== "undefined") {
			switch (re.display_status) {
				case 'off':
					displayOff();
					break;
				case 'on':
					displayOn();
					break;
				default:
					console.warn('[FetchDataCB] unknown display status ' + re.display_status);
					break;
			}
		} else {
			displayOn();
		}

		if (typeof (re.display_name) !== "undefined") {
			setDisplayName(re.display_name);
		}

		if (typeof (re.display_user) !== "undefined") {
			setDisplayUser(re.display_user);
		}

		if (typeof (re.owner_logo) !== "undefined") {
			setOwnerLogo(re.owner_logo);
		}

		if (typeof (re.create_time) !== "undefined") {
		}

		if (typeof (re.display_updated) !== "undefined") {
			_DISPLAY_UPDATED = re.display_updated;
			setLocal('display_updated', _DISPLAY_UPDATED);
		}

		var render = false;

		if (typeof (re.show_stats) !== "undefined") {
			_SHOW_STATS = re.show_stats ? true : false;
			setLocal('show_stats', _SHOW_STATS);
			render = true;
		}
		if (typeof (re.overlay_conf) !== "undefined") {
			console.warn(re.overlay_conf);
			if (re.overlay_conf !== null && re.overlay_conf.hasOwnProperty('uuid')) {
				if (_overlayApp && _overlayApp !== re.overlay_conf.uuid) {
					var overlayNum = getStoryLoc(_overlayApp);
					if (overlayNum >= 0) {
						stopOverlay(overlayNum);
						_applicationData.splice(overlayNum, 1);
					}
					document.getElementById('overlay').innerHTML = "";
					document.getElementById('overlay').style.display = "none";
				}
				_overlayApp = re.overlay_conf.uuid;
			} else {
				if (_overlayApp) {
					var overlayNum = getStoryLoc(_overlayApp);
					if (overlayNum >= 0) {
						stopOverlay(overlayNum);
						_applicationData.splice(overlayNum, 1);
					}
					document.getElementById('overlay').innerHTML = "";
					document.getElementById('overlay').style.display = "none";
				}
				_overlayApp = null;
			}
			setLocal('overlay_app', _overlayApp);
		}
		if (typeof (re.show_title) !== "undefined") {
			_SHOW_TITLE = re.show_title ? true : false;
			setLocal('show_title', _SHOW_TITLE);
			render = true;
		}
		if (typeof (re.show_logo) !== "undefined") {
			_SHOW_LOGO = re.show_logo ? true : false;
			setLocal('show_logo', _SHOW_LOGO);
			render = true;
		}
		if (typeof (re.show_next) !== "undefined") {
			_SHOW_NEXT = re.show_next ? true : false;
			setLocal('show_next', _SHOW_NEXT);
			render = true;
		}
		if (typeof (re.show_title_clock) !== "undefined") {
			_SHOW_TITLE_CLOCK = re.show_title_clock ? JSON.parse(re.show_title_clock) : false;
			setLocal('show_title_clock', _SHOW_TITLE_CLOCK);
			render = true;
		}

		if (typeof (re.show_stats_clock) !== "undefined") {
			_SHOW_STATS_CLOCK = re.show_stats_clock ? JSON.parse(re.show_stats_clock) : false;
			setLocal('show_stats_clock', _SHOW_STATS_CLOCK);
			render = true;
		}

		if (typeof (re.time_check_epoch) !== "undefined") {
			var nowTime = Date.now();

			_TIME_CHECK = nowTime - (parseInt(re.time_check_epoch) * 1000);

		}

		var paletteChanged = false;
		if (typeof (re.view_distance) !== "undefined") {
			if (re.view_distance !== _viewDistance) {
				_viewDistance = re.view_distance;
				setLocal('view_distance', _viewDistance);
				paletteChanged = true;
			}


		}

		if (render) {
			engineLayout();
		}

		var redoLocalFlow = false;
		var justLoaded = [];
		var changesPending = false;
		if (typeof (re.apps) !== "undefined") {


			for (var i = 0; i < re.apps.length; ++i) {

				var app = re.apps[i];

				if (!app.uuid) {
					continue;
				}
				var loc = getStoryLoc(app.uuid);

				// different app that we have here so lets add it to the list
				if (loc === -1) {
					if (typeof (app.deleted) === 'undefined' || !app.deleted) {
						redoLocalFlow = true;
						_applicationData.push(app);

						justLoaded.push(_applicationData.length - 1);
						loadApplication(_applicationData.length - 1);
					}

				} else {

					if (typeof (app.deleted) !== 'undefined' && app.deleted) {
						redoLocalFlow = true;
						unloadApplication(loc);
					} else {
						// old app, lets check different values
						// Source has changed
						if (typeof (app.source) !== "undefined") {


							// do we just have source delay??
							if (typeof (app.source.delayed) !== "undefined" && app.source.delayed) {
								if (typeof _applicationData[loc].source.delayed === 'undefined' || _applicationData[loc].source.delayed === null) {
									_applicationData[loc].source.delayed = new Date();
								}
							} else {
								// or has the source changed
								_applicationData[loc].source = app.source;
								if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[loc].uuid === _overlayApp) {
									ValotaEngine.ChromeOS.overlayAppChangedSource(_applicationData[loc].source);
								} else {
									if (_applicationData[loc].ready) {
										runFunction(_applicationData[loc].container.contentWindow.ValotaSourceDataHasChanged, _applicationData[loc].uuid);
									} else {
										if (_applicationData[loc].changesPending.indexOf('ValotaSourceDataHasChanged') === -1) {
											_applicationData[loc].changesPending.push('ValotaSourceDataHasChanged');
										}
									}
								}
							}


						}

						// palette
						if (typeof (app.palette) !== "undefined") {
							_applicationData[loc].palette = app.palette;
							preloadBgs(loc);
							if (loc === currentStory) {
								loadBg(loc);
							}
							if (!paletteChanged) {
								if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[loc].uuid === _overlayApp) {
									ValotaEngine.ChromeOS.overlayAppChangedPalette(_applicationData[loc].palette);
								} else {
									if (_applicationData[loc].ready) {
										runFunction(_applicationData[loc].container.contentWindow.ValotaPaletteHasChanged, _applicationData[loc].uuid);
									} else {
										if (_applicationData[loc].changesPending.indexOf('ValotaPaletteHasChanged') === -1) {
											_applicationData[loc].changesPending.push('ValotaPaletteHasChanged');
										}
									}
								}
							}
						}

						// customs
						if (typeof (app.customs) !== "undefined") {
							_applicationData[loc].customs = app.customs;
							if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[loc].uuid === _overlayApp) {
								ValotaEngine.ChromeOS.overlayAppChangedCustoms(_applicationData[loc].customs);
							} else {
								if (_applicationData[loc].ready) {
									runFunction(_applicationData[loc].container.contentWindow.ValotaCustomsHaveChanged, _applicationData[loc].uuid);
								} else {
									if (_applicationData[loc].changesPending.indexOf('ValotaCustomsHaveChanged') === -1) {
										_applicationData[loc].changesPending.push('ValotaCustomsHaveChanged');
									}
								}
							}
						}

						// name
						if (isset(app.name)) {
							_applicationData[loc].name = app.name;
						}

						//There are changes pending
						if (!changesPending && _applicationData[loc].changesPending.length > 0) {
							changesPending = true;
						}

					}

				}


			}

			setLocal('application_data', _applicationData);


		}
		if (paletteChanged) {
			for (var i in _applicationData) {
				if (justLoaded.indexOf(i) !== -1) {
					continue;
				}
				if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[i].uuid === _overlayApp) {
					ValotaEngine.ChromeOS.overlayAppChangedPalette(_applicationData[i].palette);
				} else {
					preloadBgs(i);

					if (_applicationData[i].ready) {
						runFunction(_applicationData[i].container.contentWindow.ValotaPaletteHasChanged, _applicationData[i].uuid);
					} else {
						if (_applicationData[i].changesPending.indexOf('ValotaPaletteHasChanged') === -1) {
							_applicationData[i].changesPending.push('ValotaPaletteHasChanged');
						}
					}
					if (!changesPending && _applicationData[i].changesPending.length > 0) {
						changesPending = true;
					}
				}
			}
		}
		if (changesPending) {
			setTimeout(runPendingFunctions, 10000); // run pending functions after 10 sec
		}

		if (typeof (re.flow) !== "undefined") {
			if (typeof (re.flow.deleted) !== "undefined" && re.flow.deleted) {
				// flow has been deleted
				if (_FLOW_HANDLER) {
					saveLog();

				}

				_FLOW_HANDLER = null;
				_flow = null;
				setLocal('flow', null);
				runStories();
			} else {
				//update or insert flow
				_flow = re.flow;
				setLocal('flow', _flow);
				reFlow();
			}

		} else if (redoLocalFlow) {
			// see if we have an app
			_flow = null;
			setLocal('flow', null);
			runStories();
		}
	} else {
		document.getElementById('valota_emergency').html = "";
		document.getElementById('valota_emergency').style.display = "none";
	}

	if (!hasApps()) {
		showWindow('waiting_for_content');
	}
}